import React, { useRef, useState, useEffect } from "react"
import { findDOMNode } from "react-dom"
import VideoPlayer from "./VideoPlayer"
import { Parallax } from "react-scroll-parallax"
import { Link } from "gatsby"
import screenfull from "screenfull"

import useWindowSize from "../../hooks/useWindowSize"
const PortfolioListItem = ({ item, index }) => {
  // even
  var unmute = false
  const { width, height } = useWindowSize()

  const [changedWidth, setChangedWidth] = useState(width)
  const [muted, setMuted] = useState(true)
  const [fullscreen, setFullscreen] = useState(false)
  let [shouldPlay, updatePlayState] = useState(false)

  function isLeft() {
    return index % 2 == 0
  }
  //odd
  function isRight() {
    return Math.abs(index % 2) == 1
  }

  const ref = useRef()

  if (typeof window !== `undefined`) {
    var el = document.getElementById(ref)
  } else {
    var el = null
  }
  const handleToggleMute = () => {
    setMuted(current => !current)
  }

  const handleFullscreen = event => {
    event.preventDefault()
    setMuted(false)
    // setFullscreen(true) // second approach
    screenfull.request(findDOMNode(ref.current))
  }

  useEffect(() => {
    setTimeout(() => {
      // width must be changed after window is rendered
      // a call while rendering will not return any value
      setChangedWidth(window.innerWidth)
    }, 100)
  })
  Number.prototype.leadingZeros = function (size) {
    var s = String(this)
    while (s.length < (size || 2)) {
      s = "0" + s
    }
    return s
  }
  const categories = categories => {
    return (
      <>
        <span>Kategorie : </span>
        {categories.map((category, idx) => {
          return (
            <Link key={idx} to={`/category/${category}`}>
              {category}
            </Link>
          )
        })}
      </>
    )
  }
  return (
    <div
      key={index}
      className="parallax-item fl-wrap"
      style={{ height: changedWidth > 1064 ? "100vh" : "100vh" }}
    >
      <div className="row videowrapit">
        {isRight() ? <div className="col-md-5"></div> : ""}
        <div className="col-md-7 unsetflex">
          <div
            className={`parallax-header ${
              isRight() ? "right-parallax-header" : ""
            }`}
          >
            {categories(item.node.childMarkdownRemark.frontmatter.categories)}
          </div>
          <div className="iconWrapper">
            <i
              className={`fal fa-volume-${muted ? "up" : "mute"}`}
              onClick={() => {
                handleToggleMute
              }}
            />
          </div>
          <VideoPlayer
            ref={ref}
            isLeft={isLeft()}
            muted={muted}
            fullscreen={fullscreen}
            onToggleMute={handleToggleMute}
            videoURL={item.node.childMarkdownRemark.frontmatter.video.publicURL}
          />

          <div
            className="videoDescription"
            dangerouslySetInnerHTML={{
              __html: item.node.childMarkdownRemark.html,
            }}
          ></div>
        </div>
        <Parallax
          disabled={changedWidth > 1064 ? false : true}
          y={["-170px", "100px"]}
          className={`parallax-text ${isLeft() ? "right" : "left"}-pos`}
          styleInner={{ color: "grey", fontSize: "200px" }}
        >
          <h3>
            <a
              href="/"
              onClick={e => {
                item.node.childMarkdownRemark.frontmatter.link
                  ? null
                  : e.preventDefault()
              }}
              target="_blank"
            >
              {item.node.childMarkdownRemark.frontmatter.title}
            </a>
          </h3>
          <h4>
            <span>{item.node.childMarkdownRemark.frontmatter.subtitle}</span>
          </h4>

          <a
            href="/"
            onClick={e => {
              e.preventDefault()
              handleFullscreen(e)
            }}
            target="_blank"
            className="btn float-btn flat-btn color-btn playerbtn"
          >
            Vollbild öffnen
          </a>
        </Parallax>

        <div className="album-thumbnails">
          <Parallax
            disabled={changedWidth > 1064 ? false : true}
            y={["-150px", "100px"]}
            className="parallax image-wrapper"
          >
            <img
              src={
                item.node.childMarkdownRemark.frontmatter.images[0]
                  .childImageSharp.fluid.src
              }
              alt={item.node.childMarkdownRemark.frontmatter.title}
              className={`respimg small ${isRight() ? "leftTop" : ""}`}
            />
          </Parallax>

          <Parallax
            disabled={changedWidth > 1064 ? false : true}
            y={["-100px", "100px"]}
            className="parallax image-wrapper"
          >
            <img
              src={
                item.node.childMarkdownRemark.frontmatter.images[1]
                  .childImageSharp.fluid.src
              }
              alt={item.node.childMarkdownRemark.frontmatter.title}
              className={`${
                isLeft() ? "bgvid right" : "respimg small leftBottom"
              }`}
            />
          </Parallax>
        </div>
      </div>
      <div className={`parallax-item-number ${isLeft() ? "rg" : "lf"}-num`}>
        .{index.leadingZeros(2)}
      </div>
    </div>
  )
}

export default PortfolioListItem
