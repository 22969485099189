import React, { useEffect } from "react"
import { Parallax } from "react-scroll-parallax"
import "./portfolio-list.css"
import { Link, graphql, useStaticQuery } from "gatsby"

import SecLines from "../sec-lines/sec-lines"
import PortfolioListItem from "./PortfolioListItem"

const PortfolioList = () => {
  const fileQuery = graphql`
    {
      videos: allFile(
        filter: {
          sourceInstanceName: { eq: "visuals" }
          extension: { eq: "md" }
        }
        sort: { order: ASC, fields: childMarkdownRemark___frontmatter___date }
      ) {
        edges {
          node {
            id
            childMarkdownRemark {
              frontmatter {
                title
                subtitle
                categories
                link
                date
                video {
                  publicURL
                }
                images {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
              html
              excerpt
            }
          }
        }
      }
    }
  `

  const { videos } = useStaticQuery(fileQuery)

  return (
    <div className="content">
      <div className="single-page-decor"></div>
      <div className="single-page-fixed-row">
        <div className="scroll-down-wrap">
          <div className="mousey">
            <div className="scroller"></div>
          </div>
          <span>Runter scrollen</span>
        </div>
        <Link to="/" className="single-page-fixed-row-link">
          <i className="fal fa-arrow-left"></i> <span>Zum Start</span>
        </Link>
      </div>

      <section data-scrollax-parent="true" className="dark-bg">
        <Parallax
          y={[-100, 100]}
          className="section-subtitle"
          styleInner={{ color: "grey", fontSize: "100px", top: "70px" }}
        >
          VIDEO & VISUALS
        </Parallax>
        <div className="container">
          {videos.edges.map((item, index) => {
            return item.node.childMarkdownRemark.frontmatter.video &&
              item.node.childMarkdownRemark.frontmatter.video.publicURL ? (
              <PortfolioListItem key={index} item={item} index={index} />
            ) : null
          })}
        </div>
        <SecLines />
      </section>

      <section className="dark-bg2 small-padding order-wrap">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h3>Bereit um Ihr Projekt zu starten?</h3>
            </div>
            <div className="col-md-4">
              <Link to="/kontakt" className="btn flat-btn color-btn">
                Kontaktieren Sie mich
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default PortfolioList
