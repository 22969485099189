import React, { forwardRef, useState, useEffect } from "react"
import { Waypoint } from "react-waypoint"
import ReactPlayer from "react-player"
import screenfull from "screenfull"
const VideoPlayer = forwardRef((props, ref) => {
  const { videoURL, muted, fullscreen, onToggleMute } = props

  let [shouldPlay, updatePlayState] = useState(false)
  let [muteState, setMuteState] = useState(true)
  let handleEnterViewport = () => updatePlayState(true)
  let handleExitViewport = () => updatePlayState(false)

  // second approach, but a second time fullscreen-button pressed is not working
  // useEffect(() => {
  //   console.log("UE")
  //   if (fullscreen) {
  //     console.log("fullscreen")

  //     const videoElem = ref.current.getInternalPlayer()
  //     console.log("videoElem", videoElem)
  //     // screenfull.request(videoElem)
  //     // screenfull.request(findDOMNode(videoElem))
  //   }
  // }, [fullscreen])

  return (
    <Waypoint onEnter={handleEnterViewport} onLeave={handleExitViewport}>
      <div style={{ height: "unset" }}>
        <div className="iconWrapper">
          <i
            className={`fal fa-volume-${muted ? "up" : "mute"}`}
            onClick={onToggleMute}
          />
          <i
            className={`fal fa-${shouldPlay ? "stop" : "play"}-circle`}
            onClick={() => {
              updatePlayState(!shouldPlay)
            }}
          />
        </div>

        <ReactPlayer
          ref={ref}
          muted={muted}
          loop={true}
          width="100%"
          height="unset"
          playing={shouldPlay}
          url={props.videoURL}
          className={`bgvid ${props.isLeft ? "left" : "right"}`}
        />
      </div>
    </Waypoint>
  )
})
export default VideoPlayer
